<template>
    <div class="dashboard full-screen">
      <h1>Rate Limit Dashboard</h1>
      
      <div v-if="isLoading" class="loading">
        <div class="loading-spinner"></div>
        Loading dashboard data...
      </div>
      
      <div v-else-if="error" class="error">
        <div class="error-message">
          <i class="error-icon">⚠️</i>
          {{ error }}
        </div>
        <button @click="fetchStats" class="retry-button">Retry</button>
      </div>
      
      <div v-else class="stats-grid">
        <!-- Quick Stats Row -->
        <div class="stat-card quick-stats">
          <h3>Real-Time Overview</h3>
          <div class="quick-stats-grid">
            <div class="quick-stat">
              <div class="stat-value">{{ stats.total_requests }}</div>
              <div class="stat-label">Total Requests</div>
            </div>
            <div class="quick-stat">
              <div class="stat-value">{{ stats.unique_ips }}</div>
              <div class="stat-label">Unique IPs</div>
            </div>
            <div class="quick-stat">
              <div class="stat-value">{{ stats.unique_emails }}</div>
              <div class="stat-label">Unique Users</div>
            </div>
            <div class="quick-stat">
              <div class="stat-value">{{ stats.requests_24h }}</div>
              <div class="stat-label">Last 24h Requests</div>
            </div>
          </div>
        </div>
  
        <!-- Usage Trends -->
        <div class="stat-card">
          <h3>24-Hour Activity</h3>
          <canvas ref="hourlyChart" width="400" height="300"></canvas>
        </div>
  
        <!-- Weekly Trends -->
        <div class="stat-card">
          <h3>7-Day Activity</h3>
          <canvas ref="weeklyChart"></canvas>
        </div>
  
        <!-- Top Users -->
        <div class="stat-card">
          <h3>Top Users (Last 7 Days)</h3>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Requests</th>
                  <th>Last Active</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in stats.top_users" :key="user.email">
                  <td>{{ user.email }}</td>
                  <td>{{ user.count }}</td>
                  <td>{{ formatDate(user.last_active) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <!-- Most Requested Games -->
        <div class="stat-card">
          <h3>Most Requested Games</h3>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>Teams</th>
                  <th>Requests</th>
                  <th>League</th>
                  <th>Last Request</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="game in stats.game_details" :key="game.game_id">
                  <td>{{ game.teams || 'Unknown' }}</td>
                  <td>{{ game.count }}</td>
                  <td>{{ game.league || 'N/A' }}</td>
                  <td>{{ formatDate(game.last_request) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <!-- Rate Limit Violations -->
        <div class="stat-card">
          <h3>Rate Limit Violations</h3>
          <div class="violations-summary">
            <div class="violation-stat">
              <span>Today's Violations:</span>
              <span class="violation-count">{{ stats.violations?.today || 0 }}</span>
            </div>
            <div class="violation-stat">
              <span>This Week:</span>
              <span class="violation-count">{{ stats.violations?.week || 0 }}</span>
            </div>
          </div>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>IP</th>
                  <th>Violations</th>
                  <th>Last Violation</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="v in stats.violations?.recent" :key="v.ip">
                  <td>{{ v.ip }}</td>
                  <td>{{ v.count }}</td>
                  <td>{{ formatDate(v.last_violation) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
  import Chart from 'chart.js/auto'
  
  const stats = ref({
    total_requests: 0,
    unique_ips: 0,
    unique_emails: 0,
    requests_24h: 0,
    top_users: [],
    game_details: [],
    hourly_activity: [
      { hour: '2024-03-20T00:00:00', count: 0 }
    ],
    weekly_activity: [
      { date: '2024-03-14', count: 0 }
    ],
    violations: {
      today: 0,
      week: 0,
      recent: []
    }
  })
  
  const hourlyChart = ref(null)
  const weeklyChart = ref(null)
  const isLoading = ref(true)
  const error = ref(null)
  let chart = null
  let refreshInterval = null
  let weeklyChartInstance = null
  
  const fetchStats = async () => {
    isLoading.value = true
    error.value = null
    
    try {
      const response = await fetch('https://back.betboss.ai/dash/rate-limit/stats')
      if (!response.ok) {
        throw new Error(`Failed to fetch stats: ${response.statusText}`)
      }
      const data = await response.json()
      stats.value = data
      console.log('Stats loaded:', stats.value)
      
      // Use nextTick to ensure DOM is updated before initializing charts
      nextTick(() => {
        console.log('Initializing charts after data load')
        initCharts()
      })
    } catch (err) {
      error.value = err.message
      console.error('Error fetching stats:', err)
    } finally {
      isLoading.value = false
    }
  }
  
  const initCharts = () => {
    initHourlyChart()
    initWeeklyChart()
  }
  
  const initHourlyChart = () => {
    console.log('Initializing hourly chart');
    
    nextTick(() => {
        console.log('Canvas ref:', hourlyChart.value);
        console.log('Hourly data:', stats.value.hourly_activity);

        if (!hourlyChart.value) {
            console.warn('Canvas element not found');
            return;
        }

        const ctx = hourlyChart.value.getContext('2d');

        if (chart) {
            console.log('Destroying old chart');
            chart.destroy();
        }

        const hourlyData = stats.value.hourly_activity || [];
        if (hourlyData.length === 0) {
            console.warn('No hourly data available');
            return;
        }

        const labels = hourlyData.map(h => {
            const date = new Date(h.hour);
            return date.toLocaleTimeString([], { hour: '2-digit' });
        });
        const data = hourlyData.map(h => h.count);

        console.log('Chart data:', { labels, data });

        chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels,
                datasets: [{
                    label: 'Requests per Hour',
                    data,
                    fill: true,
                    backgroundColor: 'rgba(78, 159, 61, 0.2)',
                    borderColor: 'rgb(78, 159, 61)',
                    tension: 0.4,
                    pointRadius: 3,
                    pointHoverRadius: 5
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            color: '#d0e2cd'
                        }
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: '#d0e2cd',
                            precision: 0
                        },
                        grid: {
                            color: 'rgba(255, 255, 255, 0.1)'
                        }
                    },
                    x: {
                        ticks: {
                            color: '#d0e2cd',
                            maxRotation: 45,
                            minRotation: 45
                        },
                        grid: {
                            color: 'rgba(255, 255, 255, 0.1)'
                        }
                    }
                }
            }
        });
    });
  }
  
  const initWeeklyChart = () => {
    if (!weeklyChart.value) return
  
    const ctx = weeklyChart.value.getContext('2d')
    
    if (weeklyChartInstance) {
      weeklyChartInstance.destroy()
    }
  
    const weeklyData = stats.value.weekly_activity || []
    const labels = weeklyData.map(w => {
      const date = new Date(w.date)
      return date.toLocaleDateString([], { weekday: 'short', month: 'short', day: 'numeric' })
    })
    const data = weeklyData.map(w => w.count)
  
    weeklyChartInstance = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [{
          label: 'Requests per Day',
          data,
          backgroundColor: 'rgba(78, 159, 61, 0.6)',
          borderColor: 'rgb(78, 159, 61)',
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              color: '#d0e2cd'
            }
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#d0e2cd',
              precision: 0
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.1)'
            }
          },
          x: {
            ticks: {
              color: '#d0e2cd'
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.1)'
            }
          }
        }
      }
    })
  }
  
  // Auto-refresh data every 5 minutes
  const startAutoRefresh = () => {
    refreshInterval = setInterval(fetchStats, 30 * 1000)
  }
  
  onMounted(async () => {
    await fetchStats()
    startAutoRefresh()
  })
  
  onBeforeUnmount(() => {
    if (refreshInterval) {
      clearInterval(refreshInterval)
    }
    if (chart) {
      chart.destroy()
    }
  })
  
  // Add formatDate helper
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'
    return new Date(dateString).toLocaleString()
  }
  
  watch(() => stats.value, () => {
    initCharts()
  }, { deep: true })
  </script>
  
  <style scoped>
  :root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    color-scheme: light dark;
    background-color: #242424;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    margin: 0;
  }
  
  #app {
    margin: 0;
    padding: 0;
    max-width: none;
  }
  
  /* Add these base styles at the top of your existing scoped styles */
  .dashboard {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Update button styles */
  button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
    color: white;
    border-color: #1d9b32;
  }
  
  button:hover {
    border-color: #0ce72a;
  }
  
  button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }
  
  .full-screen {
    min-height: 100vh;
    /* width: 100vw; */
    /* max-width: 100vw; */
    margin: 0;
    padding: 0;
    background-color: #242424;
    color: rgba(255, 255, 255, 0.87);
    box-sizing: border-box;
  }
  
  .dashboard {
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 500px), 1fr));
    gap: 20px;
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
  }
  
  .stat-card {
    background: #333;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 1px solid #555;
    color: #d0e2cd;
  }
  
  .stat-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    border-color: #4e9f3d;
  }
  
  .stat-card h3 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #4e9f3d;
    font-size: 1.2em;
  }
  
  .stat-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    padding: 8px 0;
    border-bottom: 1px solid #444;
  }
  
  .stat-item:last-child {
    border-bottom: none;
  }
  
  .table-container {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #4e9f3d #333;
  }
  
  .table-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .table-container::-webkit-scrollbar-track {
    background: #333;
    border-radius: 8px;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background-color: #4e9f3d;
    border-radius: 8px;
    border: 2px solid #333;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px 8px;
    text-align: left;
    border-bottom: 1px solid #444;
  }
  
  th {
    background-color: #1e5128;
    font-weight: 600;
    color: #d0e2cd;
  }
  
  tr:hover {
    background-color: #3a3a3a;
  }
  
  canvas {
    height: 300px;
    background: #2a2a2a;
    border-radius: 4px;
    padding: 10px;
  }
  
  .loading {
    text-align: center;
    padding: 40px;
    color: #d0e2cd;
  }
  
  .loading-spinner {
    border: 4px solid #333;
    border-top: 4px solid #4e9f3d;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto 20px;
  }
  
  .error {
    text-align: center;
    padding: 40px;
  }
  
  .error-message {
    color: #ff6b6b;
    margin-bottom: 20px;
    padding: 15px;
    background-color: rgba(255, 107, 107, 0.1);
    border-radius: 4px;
    display: inline-block;
  }
  
  .retry-button {
    padding: 8px 16px;
    background-color: #4e9f3d;
    color: #d0e2cd;
    border: 2px solid #1e5128;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .retry-button:hover {
    background-color: #1e5128;
    color: #ffffff;
    border-color: #d0e2cd;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
    .stats-grid {
      grid-template-columns: 1fr;
    }
    
    .stat-card {
      margin-bottom: 15px;
    }
  }
  
  .quick-stats {
    grid-column: 1 / -1;
  }
  
  .quick-stats-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  
  .quick-stat {
    text-align: center;
    padding: 15px;
    background: #2a2a2a;
    border-radius: 8px;
    border: 1px solid #4e9f3d;
  }
  
  .stat-value {
    font-size: 2em;
    font-weight: bold;
    color: #4e9f3d;
    margin-bottom: 5px;
  }
  
  .stat-label {
    font-size: 0.9em;
    color: #d0e2cd;
  }
  
  .violations-summary {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .violation-stat {
    text-align: center;
    padding: 10px;
    background: #2a2a2a;
    border-radius: 4px;
  }
  
  .violation-count {
    display: block;
    font-size: 1.5em;
    color: #ff6b6b;
    font-weight: bold;
  }
  
  /* Update the grid layout for larger screens */
  @media (min-width: 1600px) {
    .stats-grid {
      grid-template-columns: repeat(3, 1fr);
    }
    
    .quick-stats {
      grid-column: 1 / -1;
    }
  }
  
  .stat-card canvas {
    width: 100% !important;
    height: 300px !important;
    background: #2a2a2a;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
  }
  </style>
