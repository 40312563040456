<template>
    <Transition name="fade">
      <div v-if="store.showStripeOverlay" class="stripe-overlay" @click="handleOverlayClick">
        <div class="stripe-modal" @click.stop>
          <button class="close-button" @click="closeOverlay">&times;</button>
          <div class="stripe-modal-content">
            <!-- Header Section -->
            <div class="modal-header">
              <h2>Upgrade to Boss Level and get unlimited picks!</h2>
              <p class="usage-alert">You have used {{ store.searchCount }} of {{ store.allowedSearches }} free picks</p>
            </div>
  
            <!-- Pricing Cards -->
            <div class="pricing-cards">
              <div v-for="plan in plans" :key="plan.id" class="pricing-card">
                <div class="plan-header">
                  <h3>{{ plan.name }}</h3>
                  <p class="picks-count">Get unlimited picks for an entire <s>month</s> <b>YEAR</b>!</p>
                  <p class="picks-count">Sign up now and get unlimited picks until {{ new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toLocaleDateString() }}!</p>
                  <!-- <p class="picks-count">3 Day Free Trial</p> -->
                </div>
                <stripe-buy-button 
                  :buy-button-id="plan.id" 
                  :publishable-key="publishableKey"
                  :client-reference-id="clientReferenceId"
                  @click="handleSubscriptionClick(plan.name)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </template>
  
  <script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { useStore } from '../stores/store';
  import { useGtm } from '@gtm-support/vue-gtm';
  
  const store = useStore();
  const gtm = useGtm();
  const trackEvent = gtm.trackEvent.bind(gtm);
  
  const clientReferenceId = ref('');
  const publishableKey = "pk_live_51OlbxxCwPAlJePWckrBDCzu7rNypjkdmITTtwNz7z2Y2fTav6i8TXAYOtzECVMPaODKjbRNDU00O6VvtOlzwfPQN00KVmzRTmO";
  
  const plans = [
    // { id: "buy_btn_1PYtvvCwPAlJePWclTe1MR2E", name: "Rookie", picks: 300 },
    // { id: "buy_btn_1PYtyfCwPAlJePWc9csHOkOw", name: "Pro", picks: 800 },
    // { id: "buy_btn_1PYtOLCwPAlJePWc6CmOGdtY", name: "Elite", picks: 2000 },
    // { id: "buy_btn_1QX4GxCwPAlJePWc4NWfWYap", name: "Boss Level", picks: 'UNLIMITED' },  //$20/week
  { id: "buy_btn_1QYxwBCwPAlJePWc9WBVj6Qp", name: "Boss Level", picks: 'UNLIMITED' },  //$35/year

  ];
  
  const handleSubscriptionClick = (planName: string) => {
    trackEvent({
      event: "stripe_overlay_intent",
      noninteraction: false
    });
  };
  
  const closeOverlay = () => {
    store.showStripeOverlay = false;
  };
  
  const handleOverlayClick = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      closeOverlay();
    }
  };
  
  onMounted(() => {
    // Load Stripe script
    const stripeScript = document.createElement('script');
    stripeScript.src = 'https://js.stripe.com/v3/buy-button.js';
    stripeScript.async = true;
    document.head.appendChild(stripeScript);
    trackEvent({
      event: "stripe_overlay",
      noninteraction: false
    });
  
    // Watch for Stripe iframe creation and message events
    window.addEventListener('message', function(event) {
      // Only handle messages from Stripe domains
      if (event.origin.includes('stripe.com')) {
        try {
          const data = event.data;
          // Check if this is a successful purchase event
          if (data.type === 'buy-button:success') {
            console.log('Purchase successful!', data);
            closeOverlay();
          }
        } catch (e) {
          console.error('Error handling Stripe message:', e);
        }
      }
    });
  });
  </script>
  
  <style scoped>
  .stripe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
  }
  
  .stripe-modal {
    background: #2a2a2a;
    border-radius: 16px;
    padding: 2rem;
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
  }
  
  .stripe-modal-content {
    color: #ffffff;
  }
  
  .modal-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .modal-header h2 {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  
  .usage-alert {
    color: #ff4444;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .pricing-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .pricing-card {
    background: #333333;
    border-radius: 12px;
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.2s;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
  }
  
  .plan-header h3 {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }
  
  .picks-count {
    color: #cccccc;
    margin-bottom: 1.5rem;
  }
  
  /* Stripe button customization */
  :deep(.stripe-buy-button) {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    background: transparent !important;
  }
  
  :deep(.stripe-buy-button iframe) {
    width: 100% !important;
    min-width: 0 !important;
    max-width: 300px !important;
    margin: 0 auto !important;
  }
  
  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1.5rem;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
  }
  
  .close-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  /* Transition animations */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  @media (max-width: 768px) {
    .stripe-modal {
      padding: 1.5rem;
    }
  
    .pricing-cards {
      grid-template-columns: 1fr;
    }
  }
  </style>
