import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home.vue";
import Stripe from "../components/Stripe.vue";
import Odds from "../components/Odds.vue";
import ReviewGrabber from "../components/ReviewGrabber.vue";
import Dev from "../components/Dev.vue";
import ThankYou from "../components/ThankYou.vue";
import Reset from "../components/Reset.vue";
import GamesScrape from "../components/GamesScrape.vue";
import TheDash from "../components/dash/TheDash.vue";
import { useStore } from "../stores/store";

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/stripe", name: "Stripe", component: Stripe },
  { path: "/odds", name: "Odds", component: Odds },
  { path: "/review-grabber", name: "ReviewGrabber", component: ReviewGrabber },
  { path: "/dev", name: "Dev", component: Dev },
  { path: "/thank_you", name: "ThankYou", component: ThankYou },
  { path: "/reset", name: "Reset", component: Reset },
  { path: "/scrape", name: "GamesScrape", component: GamesScrape },
  {
    path: "/register",
    name: "Register",
    component: Home,
    beforeEnter: (to, from, next) => {
      // This will be captured by your Android app's Firebase analytics
      next();
      // After the route is entered, redirect
      window.location.href = "https://auth.betboss.ai/register";
    }
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: async (to, from, next) => {
      // Call the backend to delete the cookie
      try {
        await fetch("https://back.betboss.ai/logout", {
          method: "GET",
          credentials: "include", // Ensure cookies are sent with the request
        });
      } catch (error) {
        console.error("Error logging out:", error);
      }

      // Clear local and session storage
      localStorage.clear();
      sessionStorage.clear();

      // Delete all cookies for .betboss.ai domain
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";domain=.betboss.ai;path=/");
      });

      // Reset store state
      const store = useStore();
      store.$reset();
      store.isLoggedIn = false;
      store.userEmail = "";
      store.userTier = "";
      store.userId = "";
      store.searchCount = 0;
      store.allowedSearches = 5;

      // Redirect to the Fief logout URL
      const redirectUri = "https://betboss.ai";
      const fiefLogoutUrl = `https://back.betboss.ai/logout?redirect_uri=${encodeURIComponent(redirectUri)}`;
      window.location.href = fiefLogoutUrl;
    }
  },
  { path: "/dash", name: "TheDash", component: TheDash },
  // Move the catch-all route to the end
  { path: "/:catchAll(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
