<template>
  <div class="user-activity">
    <div class="stats-grid">
      <!-- Daily Signups Section -->
      <div class="stat-card">
        <h3>Daily Signups (Last 30 Days)</h3>
        <div class="chart-container">
          <canvas ref="signupsChart"></canvas>
        </div>
      </div>


      <!-- Active Users Section -->
      <div class="stat-card">
        <h3>Most Active Users (24h)</h3>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Requests</th>
                <th>Unique Games</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in stats.active_users_24h" :key="user.email">
                <td>{{ user.email }}</td>
                <td>{{ user.request_count }}</td>
                <td>{{ user.unique_games }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Popular Searches Section -->
      <div class="stat-card">
        <h3>Popular Searches</h3>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>Search Query</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(search, index) in stats.popular_searches" :key="index">
                <td>{{ search.query }}</td>
                <td>{{ search.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Daily Engagement Chart -->
      <div class="stat-card">
        <h3>Daily User Engagement</h3>
        <div class="chart-container">
          <canvas ref="engagementChart"></canvas>
        </div>
      </div>


      <!-- User Patterns Section -->
      <div class="stat-card">
        <h3>Top Users by Search Volume</h3>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Searches</th>
                <th>First Seen</th>
                <th>Last Active</th>
                <th>Tiers</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in stats.user_patterns" :key="user.email">
                <td>{{ user.email }}</td>
                <td>{{ user.total_searches }}</td>
                <td>{{ formatDate(user.first_search) }}</td>
                <td>{{ formatDate(user.last_search) }}</td>
                <td>{{ user.tiers_used.join(', ') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'

const stats = ref({
  user_patterns: [],
  active_users_24h: [],
  daily_engagement: [],
  popular_searches: [],
  daily_signups: []
})

const engagementChart = ref(null)
const signupsChart = ref(null)
let chart = null
let signupsChartInstance = null

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString()
}

const fetchStats = async () => {
  try {
    const [activityResponse, signupsResponse] = await Promise.all([
      fetch('https://back.betboss.ai/dash/user-activity/stats'),
      fetch('https://back.betboss.ai/dash/daily-signups')
    ])
    const activityData = await activityResponse.json()
    const signupsData = await signupsResponse.json()
    stats.value = {
      ...activityData,
      daily_signups: signupsData
    }
    updateChart()
  } catch (error) {
    console.error('Error fetching user activity stats:', error)
  }
}

const updateChart = () => {
  if (chart) {
    chart.destroy()
  }
  if (signupsChartInstance) {
    signupsChartInstance.destroy()
  }

  // Initialize signups chart
  const signupsCtx = signupsChart.value.getContext('2d')
  signupsChartInstance = new Chart(signupsCtx, {
    type: 'line',
    data: {
      datasets: [{
        label: 'Daily Signups',
        data: stats.value.daily_signups.map(item => ({
          x: new Date(`${item.date}T12:00:00`),
          y: item.count
        })),
        borderColor: '#4CAF50',
        backgroundColor: 'rgba(76, 175, 80, 0.1)',
        tension: 0.4,
        fill: true
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM d'
            }
          },
          title: {
            display: true,
            text: 'Date'
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Number of Signups'
          }
        }
      }
    }
  })

  // Initialize engagement chart
  const ctx = engagementChart.value.getContext('2d')
  const datasets = {}
  
  // Process data for chart
  stats.value.daily_engagement.forEach(item => {
    if (!datasets[item.tier]) {
      datasets[item.tier] = {
        label: item.tier,
        data: [],
        borderColor: getRandomColor(),
        tension: 0.4,
        fill: false
      }
    }
    datasets[item.tier].data.push({
      x: new Date(`${item.date}T12:00:00`),
      y: item.count
    })
  })

  chart = new Chart(ctx, {
    type: 'line',
    data: {
      datasets: Object.values(datasets)
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM d'
            }
          },
          title: {
            display: true,
            text: 'Date'
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Number of Searches'
          }
        }
      }
    }
  })
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

onMounted(() => {
  fetchStats()
})
</script>

<style scoped>
.user-activity {
  padding: 20px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}

.stat-card {
  background-color: #333;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container {
  overflow-x: auto;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4e9f3d #333;
}

.table-container::-webkit-scrollbar {
  width: 8px;
}

.table-container::-webkit-scrollbar-track {
  background: #333;
  border-radius: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #4e9f3d;
  border-radius: 8px;
  border: 2px solid #333;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #444;
}

th {
  background-color: #2a2a2a;
}

.chart-container {
  height: 300px;
  margin-top: 10px;
}
</style> 
