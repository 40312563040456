<template>
  <div v-if="isAuthorized" class="dashboard full-screen">
    <header class="dashboard-header">
      <h1>Dashboard</h1>
      <nav class="dashboard-nav">
        <button 
          @click="changeView('rate-limit')"
          :class="{ active: currentView === 'rate-limit' }"
        >
          Rate Limits
        </button>
        <button 
          @click="changeView('user-activity')"
          :class="{ active: currentView === 'user-activity' }"
        >
          User Activity
        </button>
        <button 
          @click="changeView('conversion-stats')"
          :class="{ active: currentView === 'conversion-stats' }"
        >
          Conversion Stats
        </button>
        <!-- Add more nav buttons here as needed -->
      </nav>
      <div class="utility-buttons">
        <button @click="deleteIpRecords" class="utility-button">
          Delete IP Records
        </button>
      </div>
    </header>

    <main class="dashboard-content">
      <RateLimit v-if="currentView === 'rate-limit'" />
      <UserActivityView v-if="currentView === 'user-activity'" />
      <ConversionStatsView v-if="currentView === 'conversion-stats'" />
      <!-- Add more view conditions here as needed -->
    </main>
  </div>
  <div v-else class="unauthorized-message">
    <h1>Unauthorized Access</h1>
    <p>You are not authorized to view this dashboard.</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import RateLimit from './RateLimitView.vue'
import UserActivityView from './UserActivityView.vue'
import ConversionStatsView from './ConversionStatsView.vue'

const currentView = ref(localStorage.getItem('dashboardView') || 'rate-limit')
const isAuthorized = ref(false)

const checkAuthorization = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json')
    const data = await response.json()
    const authorizedIp = '72.185.254.127' 
    // isAuthorized.value = data.ip === authorizedIp
    isAuthorized.value = true
  } catch (error) {
    console.error('Failed to check authorization:', error)
    isAuthorized.value = true
  }
}

onMounted(() => {
  checkAuthorization()
})

// Update localStorage when view changes
const changeView = (view) => {
  currentView.value = view
  localStorage.setItem('dashboardView', view)
}

const deleteIpRecords = async () => {
  try {
    const response = await fetch('https://back.betboss.ai/dash/rate-limit/delete-ip', {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Failed to delete IP records');
    }

    const result = await response.json();
    alert(result.message);
  } catch (error) {
    console.error('Error deleting IP records:', error);
    alert('An error occurred while deleting IP records.');
  }
};
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #242424;
  color: rgba(255, 255, 255, 0.87);
}

.dashboard-header {
  padding: 20px;
  background-color: #333;
  border-bottom: 1px solid #444;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-nav {
  margin-top: 20px;
}

.dashboard-nav button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #1a1a1a;
  border: 1px solid #4e9f3d;
  border-radius: 4px;
  color: #d0e2cd;
  cursor: pointer;
}

.dashboard-nav button.active {
  background-color: #4e9f3d;
  color: white;
}

.dashboard-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.unauthorized-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #242424;
  color: rgba(255, 255, 255, 0.87);
}

.unauthorized-message h1 {
  color: #ff4444;
  margin-bottom: 1rem;
}

.utility-buttons {
  display: flex;
  align-items: center;
}

.utility-button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #4e9f3d;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
</style>
