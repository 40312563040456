<template>
  <div class="dev-container">
    <h1>{{ title }}</h1>
    <button @click="incrementCount" class="counter-button">
      Count: {{ count }}
    </button>
    <p>{{ message }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const title = ref('Dev Component');
const count = ref(0);
const message = ref('Welcome to the Dev component!');

const incrementCount = () => {
  count.value++;
};
</script>

<style scoped>
.dev-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  background-color: #333;
  border-radius: 8px;
  color: #fff;
}

.counter-button {
  background-color: #4E9F3D;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
  transition: background-color 0.3s ease;
}

.counter-button:hover {
  background-color: #3d8030;
}

h1 {
  color: #4E9F3D;
}
</style>
