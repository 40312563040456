<template>
  <footer class="footer">
    <div class="container">
      <p>
        <a href="#" @click.prevent="openContactModal">Contact Us</a> | 
        <a href="#" @click.prevent="openTermsModal">Terms of Use</a>
      </p>
    </div>
    <!-- Terms Modal -->
    <div v-if="isTermsModalOpen" class="custom-modal-overlay" @click="closeModal">
      <div class="custom-modal-content" @click.stop>
        <button class="close-button" @click="closeModal">×</button>
        <h2>Terms of Use</h2>
        <div class="terms-content">
          <h2>Terms and Conditions</h2>
          <p>Welcome to Betboss. By accessing or using our website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use our website.</p>

          <h3>1. General Information</h3>
          <p>Betboss provides betting advice and predictions generated by artificial intelligence. The information provided is based on data available at the time of generation and is subject to change. We do not guarantee the accuracy, completeness, or reliability of any advice or prediction provided.</p>

          <h3>2. No Financial Advice</h3>
          <p>The content on this site is for informational purposes only and should not be considered as financial advice. Betboss is not a financial advisor, and the information provided should not be used as a substitute for professional financial advice.</p>

          <h3>3. Risk Acknowledgment</h3>
          <p>By using our site, you acknowledge that betting involves financial risk and that you may lose money. You agree that Betboss is not responsible for any losses incurred as a result of using our advice or predictions.</p>

          <h3>4. Limitation of Liability</h3>
          <p>Betboss, its affiliates, and its employees shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use of or inability to use our website or services.</p>

          <h3>5. User Responsibility</h3>
          <p>Users are responsible for ensuring that their use of our site complies with all applicable laws and regulations. You agree not to use our site for any unlawful or prohibited activities.</p>

          <h3>6. Changes to Terms</h3>
          <p>We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the site after any changes indicates your acceptance of the new terms.</p>

          <h3>7. Governing Law</h3>
          <p>These terms and conditions are governed by the laws of Florida. Any disputes arising from these terms will be resolved in the courts of Florida.</p>

          <h3>8. Contact Information</h3>
          <p>If you have any questions about these terms and conditions, please contact us at <a href="mailto:cs@betboss.ai">cs@betboss.ai</a>.</p>
        </div>
      </div>
    </div>
    <!-- Contact Modal -->
    <div v-if="isContactModalOpen" class="custom-modal-overlay" @click="closeModal">
      <div class="custom-modal-content" @click.stop>
        <button class="close-button" @click="closeModal">×</button>
        <h2>Contact Us</h2>
        <form @submit.prevent="submitContactForm">
          <input type="email" v-model="email" placeholder="Your email" required />
          <textarea v-model="message" placeholder="Type your message here..." required></textarea>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const isTermsModalOpen = ref(false);
const isContactModalOpen = ref(false);
const email = ref('');
const message = ref('');

const openTermsModal = () => {
  isTermsModalOpen.value = true;
};

const openContactModal = () => {
  isContactModalOpen.value = true;
};

const closeModal = () => {
  isTermsModalOpen.value = false;
  isContactModalOpen.value = false;
};

const submitContactForm = async () => {
  try {
    await axios.post('https://back.betboss.ai/contact_us', { email: email.value, message: message.value });
    alert('Message sent successfully!');
    email.value = '';
    message.value = '';
    closeModal();
  } catch (error) {
    alert('Failed to send message.');
  }
};
</script>

<style scoped>
.footer {
  text-align: center;
  background-color: #333;
  color: #d0e2cd;
}

a {
  color: #0ce72a;
  text-decoration: none;
}

a:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* Custom Modal Styles */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal-content {
  background-color: #333;
  padding: 2rem;
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  color: #d0e2cd;
  position: relative;
  text-align: left;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}

.terms-content {
  margin-top: 1rem;
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #0ce72a #333;
}

/* Webkit-based browsers */
.terms-content::-webkit-scrollbar {
  width: 8px;
}

.terms-content::-webkit-scrollbar-track {
  background: #333;
}

.terms-content::-webkit-scrollbar-thumb {
  background-color: #0ce72a;
  border-radius: 10px;
}

input[type="email"],
textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button[type="submit"] {
  background-color: #0ce72a;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0bbf24;
}
</style>

