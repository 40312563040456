<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Games from "./Games.vue";
import TopBar from "./TopBar.vue";
import Footer from "./Footer.vue";
const router = useRouter();

</script>

<template>
  <div>
    <TopBar />
  </div>
  <div class="container">
    <div class="table-ui">
      <Games />
    </div>
  </div>

  <div>
    <Footer />
  </div>
</template>

<style scoped>
.container {
  display: flex;
}

.chat-ui {
  width: 50%;
  /* Add any additional styling for chat UI here */
}

.table-ui {
  width: 100%;
  /* Add any additional styling for table UI here */
}
</style>
