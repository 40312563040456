<template>
  <div class="div-containing-buttons">
    <details class="details-toggle">
      <summary class="clickable-summary">
        <i class="chevron fas fa-chevron-right"></i>
        <i :class="`fas ${selectedLeagueIcon}`"></i>
        <span>{{ selectedLeagueName }}</span>
      </summary>
      <div class="details-content">
        <button v-for="league in leagues" :key="league.id" class="clickable-button"
          :class="{ 'selected-league': selectedLeague === league.id }" @click="selectLeague(league.id)">
          <i :class="`fa-solid ${league.icon}`"></i> {{ league.name }}
        </button>
        <button class="clickable-button" @click="showCustomMatchupContainer"><i class="fas fa-binoculars"></i> Custom
          Search</button>
      </div>
    </details>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from "../stores/store";
import { useGtm } from "@gtm-support/vue-gtm";
import { useMapsStore } from "../stores/maps";

const gtm = useGtm();
const trackEvent = gtm.trackEvent.bind(gtm);
const store = useStore();
const mapsStore = useMapsStore();

function showCustomMatchupContainer() {
  store.toggleCustomMatchupVisibility();
  const detailsElement = document.querySelector('.details-toggle');
  if (detailsElement) {
    detailsElement.removeAttribute('open');
  }
}

const leagues = mapsStore.getLeagueIdNameIcon;

const selectedLeague = computed({
  get: () => store.selectedLeague,
  set: (value) => store.setSelectedLeague(value)
});

const selectedLeagueIcon = computed(() => {
  return leagues.find(league => league.id === store.selectedLeague)?.icon || "fa-basketball-ball";
});

const selectedLeagueName = computed(() => {
  return leagues.find(league => league.id === store.selectedLeague)?.name || "Select League";
});

async function leagueOddsFetch(league: string, track: boolean = true) {
  try {
    if (track) {
      console.log("league selected", league);
      trackEvent({
        event: "league_select",
        noninteraction: false,
        user_ip: store.userIp,
        user_email: store.userEmail ? store.userEmail.replace('@', '-') : undefined,
        user_tier: store.userTier || undefined,
        user_id: store.userId || undefined
      });
    }
    const response = await axios.get(`https://back.betboss.ai/get_sport_odds?sport_name=${league}`);
    store.updateLeagueOdds(response.data);
  } catch (error) {
    console.error(error);
  }
}

function selectLeague(leagueId) {
  store.selectedLeague = leagueId;
  const league = leagues.find(l => l.id === leagueId);
  if (league) {
    leagueOddsFetch(league.id);
  }
  const detailsElement = document.querySelector('.details-toggle');
  if (detailsElement) {
    detailsElement.removeAttribute('open');
  }
}

onMounted(() => {
  const defaultLeagueId = store.selectedLeague;
  leagueOddsFetch(defaultLeagueId, false);
});
</script>

<style scoped>
@import "@/assets/games.css";

.selected-league {
  background-color: #13c823;
  color: white;
  outline: 2px solid #e9e9e9;
  outline-offset: -2px;
}

.details-toggle,
.custom-search-button {
  flex-basis: 100%;
  border: 2px solid #1e5128;
  border-radius: 12px;
  background-color: #4e9f3d;
  color: #d0e2cd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.details-toggle:hover,
.custom-search-button:hover {
  background-color: #1e5128;
  color: #ffffff;
  border-color: #d0e2cd;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
}

.clickable-summary {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  user-select: none;
  gap: 5px;
}

.details-content {
  background-color: #222;
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
}

.details-content button {
  text-align: left;
}

.clickable-summary .chevron {
  margin-right: 10px;
  transition: transform 0.2s ease;
}

.details-toggle[open] .clickable-summary .chevron {
  transform: rotate(90deg);
}

.details-toggle {
  flex-basis: 100%;
  line-height: 0;
}
</style> 