<template>
  <div class="conversion-stats full-screen">
    <h1>Conversion Statistics</h1>

    <div v-if="loading" class="loading">
      <div class="loading-spinner"></div>
      Loading conversion data...
    </div>

    <div v-else-if="error" class="error">
      <div class="error-message">
        <i class="error-icon">⚠️</i>
        {{ error }}
      </div>
      <button @click="fetchConversionData" class="retry-button">Retry</button>
    </div>

    <div v-else class="conversion-content">
      <div class="metrics">
        <div class="metric-card">
          <h3>Total Guests</h3>
          <p>{{ totalGuests }}</p>
        </div>
        <div class="metric-card">
          <h3>Total Conversions</h3>
          <p>{{ totalConversions }}</p>
        </div>
        <div class="metric-card">
          <h3>Conversion Rate</h3>
          <p>{{ conversionRate }}%</p>
        </div>
        <div class="metric-card">
          <h3>Users with Multiple IPs</h3>
          <p>{{ multipleIpsUsers.length }}</p>
        </div>
      </div>

      <div class="chart-container">
        <canvas ref="conversionChart"></canvas>
      </div>

      <div class="table-section">
          <h3>Users with Multiple IPs</h3>
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Tiers</th>
                <th>IP Address</th>
                <th>Activity Count</th>
                <th>First Activity</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="user in multipleIpsUsers" :key="user.email || user.ip_count">
                <tr v-if="user.ip_details.length">
                  <td :rowspan="user.ip_details.length">{{ user.email || 'N/A' }}</td>
                  <td :rowspan="user.ip_details.length">{{ user.tiers.join(', ') }}</td>
                  <td>{{ user.ip_details[0].ip }}</td>
                  <td>{{ user.ip_details[0].activity_count }}</td>
                  <td>{{ formatDate(user.ip_details[0].first_activity) }}</td>
                  <td>{{ formatDate(user.ip_details[0].last_activity) }}</td>
                </tr>
                <tr v-for="(ipDetail, index) in user.ip_details.slice(1)" :key="ipDetail.ip">
                  <td>{{ ipDetail.ip }}</td>
                  <td>{{ ipDetail.activity_count }}</td>
                  <td>{{ formatDate(ipDetail.first_activity) }}</td>
                  <td>{{ formatDate(ipDetail.last_activity) }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

      <div class="tables-container">
        <div class="table-section">
          <h3>Converted Users</h3>
          <table>
            <thead>
              <tr>
                <th>IP Address</th>
                <th>Email</th>
                <th>Activity Count</th>
                <th>First Activity</th>
                <th>Conversion Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in convertedUsers" :key="entry.ip">
                <td>{{ entry.ip }}</td>
                <td>{{ entry.email }}</td>
                <td>{{ entry.activityCount }}</td>
                <td>{{ formatDate(entry.firstActivity) }}</td>
                <td>{{ formatDate(entry.conversionTime) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-section">
          <h3>Non-Converted Users</h3>
          <table>
            <thead>
              <tr>
                <th>IP Address</th>
                <th>Activity Count</th>
                <th>First Activity</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in nonConvertedUsers" :key="entry.ip">
                <td>{{ entry.ip }}</td>
                <td>{{ entry.activityCount }}</td>
                <td>{{ formatDate(entry.firstActivity) }}</td>
                <td>{{ formatDate(entry.lastActivity) }}</td>
              </tr>
            </tbody>
          </table>
        </div>


      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick, watch, computed } from 'vue';
import axios from 'axios';
import Chart from 'chart.js/auto';

const conversionData = ref([]);
const loading = ref(true);
const error = ref(null);
const totalGuests = ref(0);
const totalConversions = ref(0);
const conversionRate = ref(0);
const conversionChart = ref(null);
const multipleIpsUsers = ref([]);

let conversionChartInstance = null;

const convertedUsers = computed(() => {
  return conversionData.value.filter(entry => entry.converted)
    .sort((a, b) => new Date(b.conversionTime) - new Date(a.conversionTime))
});

const nonConvertedUsers = computed(() => {
  return conversionData.value.filter(entry => !entry.converted)
    .sort((a, b) => new Date(b.lastActivity) - new Date(a.lastActivity))
});

const fetchConversionData = async () => {
  loading.value = true;
  error.value = null;

  try {
    const response = await axios.get('https://back.betboss.ai/dash/conversion-stats');
    const data = response.data;

    if (data && typeof data === 'object' && Array.isArray(data.conversion_data)) {
      conversionData.value = data.conversion_data;
      totalGuests.value = data.total_requests;
      totalConversions.value = data.conversion_data.filter(entry => entry.converted).length;
      conversionRate.value = ((totalConversions.value / totalGuests.value) * 100).toFixed(2);
      multipleIpsUsers.value = data.multiple_ips_users || [];
      nextTick(() => {
        initConversionChart();
      });
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (err) {
    error.value = err.message;
    console.error('Error fetching conversion data:', err);
  } finally {
    loading.value = false;
  }
};

const initConversionChart = () => {
  if (!conversionChart.value) return;

  const ctx = conversionChart.value.getContext('2d');

  if (conversionChartInstance) {
    conversionChartInstance.destroy();
  }

  conversionChartInstance = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: ['Converted', 'Not Converted'],
      datasets: [{
        label: 'Users',
        data: [totalConversions.value, totalGuests.value - totalConversions.value],
        backgroundColor: ['#4e9f3d', '#d0e2cd']
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            color: '#d0e2cd'
          }
        },
        title: {
          display: true,
          text: 'Conversion Overview'
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: '#d0e2cd',
            precision: 0
          },
          grid: {
            color: 'rgba(255, 255, 255, 0.1)'
          }
        },
        x: {
          ticks: {
            color: '#d0e2cd'
          },
          grid: {
            color: 'rgba(255, 255, 255, 0.1)'
          }
        }
      }
    }
  });
};

const formatDate = (date) => {
  return new Date(date).toLocaleString();
};

watch(() => conversionData.value, () => {
  nextTick(() => {
    initConversionChart();
  });
}, { deep: true });

onBeforeUnmount(() => {
  if (conversionChartInstance) {
    conversionChartInstance.destroy();
  }
});

onMounted(() => {
  fetchConversionData();
});
</script>

<style scoped>
.conversion-stats {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #242424;
  color: rgba(255, 255, 255, 0.87);
}

.loading {
  text-align: center;
  padding: 40px;
  color: #d0e2cd;
}

.loading-spinner {
  border: 4px solid #333;
  border-top: 4px solid #4e9f3d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.error {
  text-align: center;
  padding: 40px;
}

.error-message {
  color: #ff6b6b;
  margin-bottom: 20px;
  padding: 15px;
  background-color: rgba(255, 107, 107, 0.1);
  border-radius: 4px;
  display: inline-block;
}

.retry-button {
  padding: 8px 16px;
  background-color: #4e9f3d;
  color: #d0e2cd;
  border: 2px solid #1e5128;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s;
}

.retry-button:hover {
  background-color: #1e5128;
  color: #ffffff;
  border-color: #d0e2cd;
}

.conversion-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.metric-card {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.chart-container {
  margin: 30px 0;
  padding: 20px;
  background-color: #333;
  border-radius: 8px;
}

.tables-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.table-section {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  max-height: 1000px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4e9f3d #333;
}

.table-section::-webkit-scrollbar {
  width: 8px;
}

.table-section::-webkit-scrollbar-track {
  background: #333;
  border-radius: 8px;
}

.table-section::-webkit-scrollbar-thumb {
  background-color: #4e9f3d;
  border-radius: 8px;
  border: 2px solid #333;
}

.table-section h3 {
  margin-bottom: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #4e9f3d;
}

th {
  background-color: #242424;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>