<template>
  <div class="thank-you-container">
    <TopBar />
    <div class="thank-you-content">
      <h1 class="thank-you-title">Thank You for Subscribing!</h1>
      <div class="message-box">
        <p>We're excited to have you on board. Your subscription has been successfully processed.</p>
        <p>You now have access to all our premium features. Enjoy!</p>
        <p class="session-id">Session ID: {{ sessionId }}</p>
      </div>
      <router-link to="/" class="home-link">Go to Home</router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
    dataLayer?: any[];
  }
}

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TopBar from './TopBar.vue';
import { useGtm } from "@gtm-support/vue-gtm";
import { useStore } from "../stores/store";
import axios from 'axios';

const route = useRoute();
const router = useRouter();
const sessionId = ref('');
const store = useStore();

const gtm = useGtm();
const trackEvent = gtm?.trackEvent.bind(gtm);

onMounted(async () => {
  sessionId.value = route.query.sesid as string;
  console.log(route.query);
  console.log(sessionId.value);
  
  // Add sessionId to dataLayer
  console.log('Adding sessionId to dataLayer:', sessionId.value);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'session_id': sessionId.value
  });

  // Emit thank_you event with user data
  if (trackEvent) {
    trackEvent({
      event: 'thank_you',
      session_id: sessionId.value,
      user_email: store.userEmail,
      user_id: store.userId,
      user_ip: store.userIp,
      value: 5
    });
  }

  await store.checkLoginStatus();
});
</script>

<style scoped>
.thank-you-container {
  min-height: 100vh;
  background-color: #1e1e1e;
  color: #ffffff;
}

.thank-you-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.thank-you-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: #4CAF50;
}

.message-box {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.message-box p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  line-height: 1.6;
}

.home-link {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.home-link:hover {
  background-color: #45a049;
}

.session-id {
  font-size: 0.9rem;
  color: #888;
  margin-top: 1rem;
}
</style>
